.sms {
    padding: 80px 10px 0 10px;
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
}

@media (max-width: 1260px) {
    .sms {
        padding: 18px 10px 0 10px;
    }
}

@media (max-width: 768px) {
    .sms {
        padding: 0px 10px 0 10px;
    }
}

.sms__info {
    padding: 16px;
    background: #ecf5f0;
    border-radius: 10px;
    text-align: center;
}

.sms__form {
    padding: 32px;

    border: 1px solid rgba(22, 33, 54, 0.16);
    border-radius: 10px;
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sms__send-text {
    text-align: center;
}

.sms__submit-btn {
    margin: 20px 0 0 0;
    border-radius: 10px;
    width: 100%;
    color: rgb(255, 255, 255);
    background: #21ba72;
    font-size: 16px;
}

.sms__submit-btn.disabled {
    background: #21ba7299;
}
