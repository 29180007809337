.banner-inner {
}
.banner-inner__content {
    position: relative;
    background: url(./../../../../assets/imgs/css/bg3.jpg) center / cover;
    height: 605px;
    padding: 80px 100px 0 166px;
    border-radius: 20px;

    max-width: 100%;
    position: relative;
}

.banner-inner-info {
    max-width: 664px;
    margin: 0 0 80px 0;
}
.banner-inner-info__title {
    color: #333;
    font-size: 52px;
    line-height: 61px;
    font-weight: 700;
    font-family: 'Glober';
}

.banner-inner__img {
    position: absolute;
    right: 100px;
    max-width: 570px;
    margin: 8px 0 0;
    width: 50%;
}
.banner-inner__img img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 100%;
}

@media (max-width: 1520px) {
    .banner-inner__content {
        padding: 80px 0 0;
    }
    .banner-inner-info {
        max-width: 1320px;
        width: 100%;

        margin: 0 auto;
        padding: 0 0 0 66px;
    }
    .banner-inner-info__title {
        max-width: 664px;
    }
    .banner-inner__img {
        right: 50px;
    }
}

@media (max-width: 1300px) {
    .banner-inner-info {
        margin-bottom: 40px;
    }
    .banner-inner__content {
        height: 630px;
    }
    .banner-inner__img {
        right: 15px;
    }
}

@media (max-width: 1254px) {
    .banner-inner__content {
        max-width: 100%;
        position: relative;
    }
}

@media (max-width: 1180px) {
    .banner-inner-info__title {
        font-size: 46px;
    }
    .banner-inner__img {
        width: 430px;
        bottom: 0;
    }
    .banner-inner__img img {
        width: 430px;
    }
}

@media (max-width: 1023px) {
    .banner-inner-info__title {
        font-size: 38px;
        line-height: 1.2;
    }
    .banner-inner-info__title {
        max-width: 600px;
    }
}

@media (max-width: 934px) {
    .banner-inner__img {
        width: 215px;
        right: 15px;
    }
    .banner-inner__img img {
        width: 215px;
    }
}

@media (max-width: 768px) {
    .banner-inner {
        padding: 0 8px;
    }
    .banner-inner__content {
        height: 690px;
        padding: 52px 60px 0;
    }
    .banner-inner-info {
        padding: 0;
    }
    .banner-inner-info__title {
        font-size: 34px;
        text-align: center;
        max-width: 664px;
    }
    .banner-inner__img {
        left: 50%;
        transform: translate(-50%, 0);
        top: 130px;
        width: 170px;
        height: 150px;
    }
}

@media (max-width: 695px) {
    .banner-inner-info__title {
        font-size: 28px;
    }
}

@media (max-width: 596px) {
    .banner-inner__content {
        height: 735px;
    }
    .banner-inner__img {
        top: 150px;
    }
}

@media (max-width: 441px) {
    .banner-inner__content {
        padding: 32px 10px 60px;
        height: 650px;
    }
    .banner-inner-info__title {
        font-size: 24px;
    }
    .banner-inner__img {
        top: 120px;
    }
}

@media (max-width: 431px) {
    .banner-inner__content {
        height: 680px;
    }
}
