.banner-loading__content {
    width: 1320px;
    max-width: 100%;
    margin: 0 auto;
    min-height: 430px;
}

.banner-loading__title {
    text-align: center;
}

.banner-loading__loader {
    margin: 2rem 0;
    display: flex;
}

.loader__wrapper {
    transform: translate(-50%, 0);
    margin-left: 50%;
}

.banner-loading__content .payment-calculation__list {
    flex-direction: column;
}

.banner-loading__content .payment-calculation__name {
    width: 100%;
    text-align: center;
}

.form-quest__codeword {
    color: #0eb203;
}

@media (max-width: 1520px) {
    .banner-loading__content {
        padding: 80px 0 0;
    }
}

@media (max-width: 1254px) {
    .banner-loading__content {
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .banner-loading__content {
        padding: 52px 60px 0;
    }
}

@media (max-width: 441px) {
    .banner-loading__content {
        padding: 32px 10px 60px;
    }
}
