.calculator {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.calculator__item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.calculator__label {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 5px;
}

.calculator__value {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}

@media (max-width: 768px) {
    .calculator {
        justify-content: space-around;
    }

    .calculator__item {
        flex-grow: 0;
    }

    .calculator__label {
        text-align: center;
    }

    .calculator__value {
        text-align: center;
    }
}
