.banner-info {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translate(-50%, 0);
}
.banner-info__content {
    background: #fff;
    padding: 10px 40px;
    width: 1320px;
    height: 120px;
    margin: 0 auto;
    border-radius: 16px;
    box-shadow: 0 3px 20px 0 rgb(0 0 0 / 10%);
}
.banner-info__list {
    height: 100%;
}
.banner-info__item {
    flex: 1;
    margin: 0 0 0 12px;
}
.banner-info__item:first-child {
    margin: 0;
}
.banner-info__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}
.banner-info__text {
    margin: 4px 0 0;
    font-size: 16px;
    line-height: 22px;
}

@media (max-width: 1325px) {
    .banner-info {
        padding: 0 20px;
        width: 100%;
        left: 0;
        transform: translate(0);
    }
    .banner-info__content {
        width: 100%;
        margin: 0;
    }
}

@media (max-width: 1300px) {
    .banner-info {
        bottom: -70px;
    }
}

/* @media (max-width: 1300px) {
	.banner-info {
		bottom: -100px;
	}
} */

@media (max-width: 1010px) {
    .banner-info {
        padding: 0 12px;
    }
    .banner-info__title {
        font-size: 20px;
    }
    .banner-info__text {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .banner-info {
        left: 50%;
        bottom: -200px;
        transform: translate(-50%, 0);

        max-width: 500px;
    }
    .banner-info__content {
        height: auto;
        padding: 20px;
    }
    .banner-info__list {
        max-width: 500px;
        display: block !important;
    }
    .banner-info__item {
        text-align: center;
        margin: 15px 0 0;
    }
    .banner-info__item:nth-child(1) {
        margin: 0;
    }
}
