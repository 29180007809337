.content-error {
    border: 2px solid red;
}
.title-error {
    color: red;
}
.btns-center .button-form {
    margin: 0;
}
.btns-center {
    justify-content: center;
}
.form-quest__title-mb-2 {
    margin-bottom: 2rem;
}
.text-center {
    display: flex;
    justify-content: center;
}
