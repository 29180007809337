.step-header {
    display: flex;
    flex-direction: column;
}

.step-header__logo {
    width: 146px;
    margin: -15px 0 15px -15px;
}

.step-header__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
}

.step-header__body {
    margin-bottom: 10px;
    width: 100%;
}

.step-header__note {
    font-weight: bold;
    margin-bottom: 10px;
}

.step-header--success {
    color: #33a547;
}

@media (max-width: 768px) {
    .step-header {
        align-items: center;
    }

    .step-header__title {
        text-align: center;
    }

    .step-header__logo {
        width: 200px;
        margin: 0 0 15px 0;
    }

    .step-header__note {
        text-align: center;
    }
}
