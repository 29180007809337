.roadmap {
    margin: 86px 0 0;
}
.roadmap__content {
    width: 1176px;
    max-width: 100%;
    margin: 0 auto;
}
.roadmap__title {
    text-align: center;

    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
}
.roadmap__list {
    position: relative;
    align-items: flex-start !important;
    margin: 64px 0 0;
}
.roadmap__list::before {
    content: '';
    position: absolute;
    top: 24px;
    left: 0;
    display: block;
    background-color: #cfcfcf;
    height: 1px;
    width: 100%;
    z-index: 1;
}
.roadmap-item {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 68px 40px 0 40px;
    text-align: center;
    position: relative;
}
.roadmap-item::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    width: 112px;
    display: block;
    height: 100%;
    background-color: #fff;
    bottom: 0;
    z-index: 2;
}
.roadmap-item:first-child:after {
    left: 0;
    width: calc(50% + 56px);
    transform: none;
}
.roadmap-item:last-child:after {
    right: 0;
    left: unset;
    width: calc(50% + 56px);
    transform: none;
}
.roadmap-item__step {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 48px;
    width: 48px;
    text-align: center;
    border-radius: 50%;
    background-color: #f5f5f7;
    z-index: 3;

    font-size: 24px;
    font-weight: 700;
    line-height: 54px;
    color: #86cf13;
}
.roadmap-item__title {
    position: relative;
    z-index: 3;

    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    word-break: break-word;
}
.roadmap-item__text {
    position: relative;
    z-index: 3;

    font-size: 16px;
    line-height: 24px;
    margin-top: 4px;
    color: #454545;
}

@media (max-width: 1024px) {
    .roadmap-item {
        padding: 68px 12px 0 12px;
    }
}

@media (max-width: 767px) {
    .roadmap-item__title {
        font-size: 18px;
        line-height: 26px;
    }
    .roadmap-item__text {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
    }
}

@media (max-width: 1010px) {
    .roadmap__title {
        font-size: 32px;
    }
}

@media (max-width: 599px) {
    .roadmap__title {
        font-size: 28px;
        line-height: 36px;
    }
}

@media (max-width: 599px) {
    .roadmap {
        margin: 32px 0 0;
    }
    .roadmap__list {
        margin: 32px 0 0;
    }
    .roadmap__title {
        text-align: left;
        padding: 0 12px;
    }
    .roadmap__list::before,
    .roadmap__list::after,
    .roadmap__item::before,
    .roadmap__item::after {
        display: none;
    }

    .roadmap__list {
        display: block !important;
    }

    .roadmap-item {
        display: block;
        padding: 0 12px 0 50px;
        margin: 20px 0 0 8px;
    }

    .roadmap-item__title,
    .roadmap-item__text {
        text-align: left;
        margin: 0;
    }

    .roadmap-item__step {
        top: -5px;
        left: 0px;
        transform: translate(0);
        width: 35px;
        height: 35px;
        line-height: 35px;
        font-size: 20px;
    }
}
