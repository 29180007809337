.container {
    position: relative;
}

.suggestionsContainerOpen {
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 10;
    background-color: #fff;
    box-shadow: 0 1px 6px 3px rgba(0, 0, 0, 0.1);
    top: calc(100% + 8px);
    border-radius: 4px;
    overflow: hidden;
    left: 0;
    right: 0;
    text-align: left;
}

.suggestionsList {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.suggestion {
    font-size: 15px;
    padding: 7px 10px;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    display: block;
    border: none;
    text-align: left;
}

.suggestion mark {
    background: none;
    color: #0094ff;
}

.suggestionFirst {
    border-top: 0;
}

.suggestionHighlighted {
    background-color: rgba(0, 124, 214, 0.15);
}

.suggestion .react-dadata__suggestion-subtitle {
    font-size: 14px;
    margin-top: 4px;
    color: #777777;
}

.suggestion .react-dadata__suggestion-subtitle-item {
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 4px;
}

.suggestion .react-dadata__suggestion-subtitle-item:last-child {
    margin-right: 0;
}
