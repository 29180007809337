.banner-adv {
    width: 1126px;
    padding: 0 12px;
    margin: 86px auto 0;
}
.banner-adv__content {
    background: url(./../../../../assets/imgs/css/bg3.jpg) center / cover;
    border-radius: 24px;
}
.banner-adv__content.row {
    align-items: normal;
    height: 226px;
}
.banner-adv__info {
    max-width: 500px;
    margin: 32px 0 32px 48px;
}
.banner-adv__title {
    margin: 0 0 20px 0;

    color: #333;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
}
.banner-adv__img {
    width: 235px;
    height: 205px;
    margin: 20px 10% 0 0;
}
.banner-adv__img img {
    width: 100%;
    height: 100%;
}

@media (max-width: 1126px) {
    .banner-adv {
        max-width: 100%;
    }
    .banner-adv__info {
        flex: 0 1 auto;
    }
    .banner-adv__img {
        flex: 0 0 auto;
    }
}

@media (max-width: 860px) {
    .banner-adv__title {
        font-size: 28px;
    }
    .banner-adv__img {
        width: 174px;
        height: 165px;
        margin: 60px 82px 0 0;
    }
}

@media (max-width: 736px) {
    .banner-adv__img {
        margin: 60px 42px 0 0;
    }
}

@media (max-width: 600px) {
    .banner-adv {
        margin-top: 32px;
    }
    .banner-adv__title {
        text-align: center;
    }
}
@media (max-width: 600px) {
    .banner-adv__info {
        max-width: 100%;
        padding: 0 24px;
        margin: 32px 0 20px;
    }
    .banner-adv__title {
        font-size: 22px;
        line-height: 1.4;
    }
}

@media (max-width: 488px) {
    .banner-adv__title {
        text-align: center;
    }
    .banner-adv__content {
        flex-direction: column;
        justify-content: center;
    }
    .banner-adv__info {
        max-width: 100%;
        padding: 0 24px;
        margin: 32px 0 20px;
    }
    .banner-adv__img {
        width: 130px;
        height: 123px;
        margin: 0 auto;
    }
    .banner-adv__content.row {
        height: auto;
    }
}
