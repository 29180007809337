.advantage {
    margin: 86px 0 0;
}
.advantage__content {
    max-width: 100%;
    width: 1320px;
    margin: 0 auto;
}
.advantage__list {
    flex-wrap: wrap;
    padding: 0 12px;
    /* margin: 0 -12px; */
}
.advantage__item {
    width: calc(50% - 24px);
    min-height: 180px;
    padding: 20px 20px 26px 40px;
    border-radius: 16px;
    background-color: #f5f5f7;
    background: #f9f9f9;
    margin: 24px 0 0;
}
.advantage__item.row {
    align-items: normal;
    align-items: stretch;
}
.advantage__info {
    min-width: 256px;
    max-width: 56%;
    margin: 10px 0 0;
}
.advantage__title {
    text-shadow: 0 1px 4px #f5f5f7;
    font-size: 26px;
    line-height: 34px;
    font-weight: 700;
}
.advantage__text {
    margin: 8px 0 0;

    font-size: 16px;
    line-height: 24px;
    text-shadow: 0 1px 4px #f5f5f7;
}
.advantage__img {
    width: 130px;
    height: 100%;
    margin: 0 20px 0 0;
}
.advantage__img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (max-width: 1320px) {
    .advantage__content {
        /* padding: 0 12px; */
    }
}

@media (max-width: 1023px) {
    .advantage__content {
        max-width: 100%;
        margin: 0 auto;
    }
    .advantage__list {
        display: block !important;
        max-width: 100%;
        margin: 0;
    }
    .advantage__item {
        flex: 0 1 100%;
        max-width: 100%;
        width: 100%;
        height: 140px;
    }
    .advantage__img {
        width: 100px;
        height: auto;
    }
    .advantage__img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .advantage__info {
        min-width: auto;
        width: 100%;
    }
}

@media (max-width: 599px) {
    .advantage {
        margin: 32px 0 0;
    }
    .advantage__item {
        padding: 12px 24px;
    }
    .advantage__title {
        font-size: 20px;
        line-height: 28px;
    }
    .advantage__text {
        font-size: 14px;
        line-height: 1.4;
    }
}

@media (max-width: 400px) {
    .advantage__item {
        padding: 20px 0px 20px 20px;
        height: auto;
        min-height: 180px;
    }
    .advantage__info {
        margin: 0 10px 0 0;
    }
}
