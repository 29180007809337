.select-default {
}

.select-default .select-default-item__control {
    padding: 10px 18px 16px 14px;
    border-radius: 16px;
    background: #f5f5f7;
    height: 58px;
    cursor: pointer;

    font-size: 24px;
    font-weight: 700;
}

.select-default .select-default-item__control {
    border: none;
}

.select-default .select-default-item__control.select-default-item__control--is-focused {
    border: none;
    box-shadow: none;
}

.select-default .select-default-item__indicator {
    padding: 4px 0px 8px 10px;
}
.select-default .select-default-item__input {
    caret-color: transparent;
}

.select-default .select-default-item__menu {
    border-radius: 16px;
    background: #f5f5f7 !important;
    z-index: 999;
}
.select-default .select-default-item__menu-list {
    padding: 0;
    z-index: 999;
}
.select-default .select-default-item__option {
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}
.select-default .select-default-item__option--is-focused,
.select-default .select-default-item__option--is-selected {
    color: inherit;
    background-color: #e5e5e5;
}
.select-default .select-default-item__option:hover {
    background-color: #e5e5e5 !important;
}
.select-default .select-default-item__option:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.select-default .select-default-item__option:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.select-default__text {
    margin: 8px 0 0;
    padding: 0 0 0 20px;

    font-weight: 600;
    color: #909090;
    font-size: 12px;
    line-height: 18px;
}
