.range-default {
    margin: 24px 0 0;
}
.range-default__block {
    font-size: 24px;
    line-height: 24px;
    padding: 12px 20px 16px;
    height: 58px;
}
.range-default__block {
    position: relative;
    flex-wrap: wrap;
    padding: 10px 20px 16px 20px;
    border-radius: 16px;
    background: #f5f5f7;
    font-size: 24px;
    font-weight: 700;
}
.range-default__value {
    font-family: Arial, Helvetica, sans-serif;
    color: #545454;
    font-size: 24px;
    font-weight: 700;
}
.range-default__unit {
    margin: 5px 0 0;

    color: #959595;
    font-size: 24px;
    font-weight: 700;
}
.range-default__slider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 320px;
    height: 4px;
    padding: 0 10px;
    margin: 0 10px;
    background: #e6e6e6;
    box-shadow: inset 0px 1px 3px rgb(0 0 0 / 30%);
    border-radius: 10px;
}
.range-default__slider .range-slider {
    height: 4px;
}
.range-default__slider .range-slider .rc-slider-rail {
    display: none;
}
.range-default__slider .range-slider .rc-slider-track {
    background: #21ba72;
    height: 4px;
    top: 0;
    margin: 0 -10px 0 -10px;
    padding: 0 10px 0 0;
    box-sizing: content-box;
}
.range-default__slider .range-slider .rc-slider-step {
    display: none;
}
.range-default__slider .range-slider .rc-slider-handle {
    margin: -13px 0 0;
    background: #0eb203;
    border: 1px solid #ccc;
    width: 20px;
    height: 20px;
    border: none;
    cursor: pointer;
    z-index: 10;
}
.range-default__slider .range-slider .rc-slider-handle::after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    margin: 1px auto 0;
    border-radius: 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.13), rgba(255, 255, 255, 0));
}
.range-default__slider .range-slider .rc-slider-handle:active {
    border: none;
    box-shadow: none;
    background-image: linear-gradient(#0000001a, #0000001f);
}

.range-default__info {
    margin: 8px 20px 0;
}
.range-default__min,
.range-default__max {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: #959595;
}
