.button-form {
    display: block;
    position: relative;
    width: 170px;
    height: 48px;
    background: #21ba72;
    box-shadow: rgb(33 186 114 / 31%) 0px 7px 16px;
    border-radius: 30px;
    transition: background-color 0.2s linear, color 0.2s linear, box-shadow 0.2s linear;
    overflow: hidden;
    padding: 0px 16px;
    margin: 0 0 0 auto;
    cursor: pointer;

    text-align: center;
    color: #fff;
    font-weight: 600;
}

.button-form:focus,
.button-form:hover {
    background-color: #86cf13;
}

.button-form::before {
    width: 140px;
    height: 112px;
    background-position: center;
    z-index: 1;
    position: absolute;
    content: '';
}

.button-form::after {
    content: '';
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: #fff;
    width: 500px;
    height: 500px;
    opacity: 0.2;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    margin: -250px 0px 0px -250px;
    -webkit-animation: button 3s infinite linear;
    -moz-animation: button 3s infinite linear;
    -o-animation: button 3s infinite linear;
    animation: button 3s infinite linear;
}

.button-form__text {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 14px 40px 19px 40px;

    align-items: center;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 700;
}

@-webkit-keyframes button {
    0% {
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -webkit-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -webkit-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
    }
}
@-moz-keyframes button {
    0% {
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -webkit-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -webkit-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
    }
}
@-o-keyframes button {
    0% {
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -webkit-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -webkit-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
    }
}
@keyframes button {
    0% {
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -webkit-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -webkit-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
    }
}
