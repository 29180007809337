.input__input textarea {
    background: #ecf5f0;
    width: 100%;
    height: 96px;
    border-radius: 8px;
    outline: 2px solid rgba(0, 0, 0, 0);

    color: #333333d8;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;

    padding: 19px 38px 19px 16px;
    overflow: hidden;
    resize: vertical;
}

.input__input textarea::placeholder {
    font-size: 14px;
    color: #33333399;
    font-weight: 700;
}

.is-valid-error .input__input textarea {
    outline: 2px solid #ef4444d8;
}

.is-valid-ok .textarea__input.input__input:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 24px;
    transform: translateY(-50%);
    background-image: url(./../../../assets/imgs/css/ok.svg);
    height: 16px;
    width: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
}

.input__input textarea:focus {
    outline: 2px solid #66ad86;
    background: #fff;
}
