.abfJWTd2g {
    white-space: nowrap;
}

.mb2XNslra .ab2XNslra {
    font: 400 11px/16px dsText, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
        Arial, sans-serif;
    letter-spacing: 0.4px;
}

.ab2XNslra {
    align-items: center;
    border-radius: inherit;
    display: inline-flex;
    position: relative;
}

.Kb2XNslra,
.Lb2XNslra {
    height: 100%;
}

.Kb2XNslra {
    align-items: center;
    display: inline-flex;
}

.Kb2XNslra,
.Lb2XNslra {
    height: 100%;
}

.ab2K5T8CO > :last-child {
    margin-right: 0;
}

.ab2K5T8CO > * {
    margin-right: 7px;
}
.wb2XNslra {
    background-color: var(--tds-color-success-fill, #00b92d);
}
.vb2XNslra,
.wb2XNslra {
    color: var(--tds-color-base-01, #fff);
}
.mb2XNslra {
    border-radius: 20px;
    height: 20px;
    min-width: 20px;
}
.kb2XNslra {
    align-items: stretch;
    box-sizing: border-box;
    color: var(--tds-color-base-01, #fff);
    display: inline-flex;
    flex-shrink: 0;
    font-size: 0;
    line-height: 0;
    outline: 0;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.ab2K5T8CO {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
}

.rb1OL0XL6,
.sb1OL0XL6 {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;
}

.pb1OL0XL6 {
    position: absolute;
    bottom: 0;
    right: 20px;
    top: 0;
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
