.input {
    margin: 16px 0 0;
    width: 100%;
}

.input__info {
    padding: 4px;
}

.input__title {
    color: #1f2937e6;
    font-size: 14px;
}

.input__error {
    color: #ef4444d8;
    font-size: 12px;
    font-weight: 700;
}

.input__input {
    position: relative;
    width: 100%;
    margin: 4px 0 0;
}

.input__input input {
    background: #ecf5f0;
    width: 100%;
    height: 52px;
    border-radius: 8px;
    outline: 2px solid rgba(0, 0, 0, 0);
    padding: 0 16px;

    color: #333333d8;
    font-size: 14px;
    font-weight: 700;
    line-height: 28px;
}

.input__input input::placeholder {
    font-size: 14px;
    color: #33333399;
    font-weight: 700;
}

.is-valid-error .input__input input {
    outline: 2px solid #ef4444d8;
}

.is-valid-ok .input__input:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(./../../../assets/imgs/css/ok.svg);
    height: 16px;
    width: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
}

.input__input input:focus {
    outline: 2px solid #66ad86;
    background: #fff;
}
