.mc_panel {
    font-family: Roboto, sans-serif;
    font-weight: lighter;
    position: fixed;
    bottom: 20px;
    left: calc(50% - 160px);
    width: 330px;
    background-color: #fff;
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    z-index: 9000;
    padding: 10px 0;
    font-size: 12px;
    opacity: 0.9;
    border-radius: 10px;
}

.mc_wrap {
    text-align: justify;
    position: relative;
    margin: 3px auto;
    padding: 0 15px;
    display: flex;
    align-items: center;
}

.mc_wrap .panel_text {
    width: 100%;
    text-align: left;
    line-height: 15px;
    color: #333;
}

.mc_close_btn {
    background-color: #f90;
    padding: 10px 7px;
    color: #fff;
    border-radius: 5px;
    transition: all 0.3s ease-in;
    text-decoration: none;
}

@media screen and (max-width: 790px) {
    .mc_close_btn {
        top: calc(50% - 18px);
    }
}

@media screen and (max-width: 480px) {
    .mc_panel {
        left: 0;
        width: 100%;
        bottom: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 6px 0;
    }
}
