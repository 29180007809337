.tab-description {
    max-width: 1344px;
    margin: 86px auto 0;
}
.tab-description__title {
    text-align: center;
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
}
.tab-description-carts {
    align-items: stretch !important;
    margin: 30px -12px 0;
}
.tab-description-carts-item {
    flex: 1.6 1;
    position: relative;
    background: #f9f9f9;
    min-height: 200px;
    border-radius: 16px;
    margin: 0 12px;
}
.tab-description-carts-item__info {
    flex: 0 0 70%;
    padding: 28px 0 20px 32px;
}
.tab-description-carts-item__title {
    text-shadow: 0 1px 4px #f5f5f7;
    color: #111;
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
}
.tab-description-carts-item__text {
    margin: 8px 0 0;

    font-size: 16px;
    line-height: 24px;
}
.tab-description-carts-item__img {
    flex: 0 0 30%;
}
.tab-description-carts-item__img img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30%;
    height: auto;
}

@media (max-width: 1150px) {
    .tab-description-carts {
        margin: 30px 5px 0;
    }
    .tab-description-carts-item__title {
        font-size: 22px;
    }
}

@media (max-width: 1023px) {
    .tab-description-carts {
        display: block !important;
    }
    .tab-description-carts {
        max-width: 788px;
        padding: 0 12px;
        margin: 30px auto 0;
    }
    .tab-description__title {
        font-size: 32px;
    }
    .tab-description-carts-item {
        min-height: 165px;
        margin: 0 0 24px 0 !important;
    }
    .tab-description-carts-item__info {
        padding: 24px 0 20px 24px;
    }
    .tab-description-carts-item__img img {
        width: 15%;
    }
    .tab-description-carts-item__info {
        flex: 0 0 77%;
    }
}

@media (max-width: 599px) {
    .tab-description {
        padding: 0 8px;
        margin: 32px 0 0;
    }
    .tab-description__title {
        text-align: left;
        font-size: 28px;
        line-height: 36px;
    }
    .tab-description-carts {
        padding: 0;
        margin: 24px 0 0;
    }
    .tab-description-carts-item__info {
        flex: 0 0 70%;
        padding: 16px 0 16px 16px;
    }
    .tab-description-carts-item__title {
        font-size: 20px;
        line-height: 24px;
    }
    .tab-description-carts-item__text {
        font-size: 14px;
        line-height: 19.6px;
    }
    .tab-description-carts-item__img img {
        width: 20%;
    }
}

@media (max-width: 540px) {
    .tab-description-carts-item {
        min-height: 130px !important;
    }
}
